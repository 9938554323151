<template>
	<div v-show="opened && route.name == 'home'" class="addToHomeScreenContainer">
		<div class="logoContainer">
			<img src="@/assets/icons/logo.png" :alt="t('common.brandName')" class="appLogo" />
		</div>

		<div class="content">
			<div class="appName">
				{{ t("common.brandName") }}
				<div class="rating">★★★★★</div>
			</div>
			<div class="description">{{ t("common.addToHomeScreenInfo") }}</div>
		</div>

		<button class="mainButton installButton" @click="addToHomescreen">
			{{ t("common.install") }}
		</button>

		<button class="closeButton unstyledButton" @click="close">
			<font-awesome-icon :icon="['fas', 'xmark']" />
		</button>
	</div>

	<div id="IOSmodal" class="overlay">
		<div class="modalContent">
			<div class="instructions">
				<div>
					{{ t("addMessages.ios1") }}
					<img class="shareIOS" src="@/assets/icons/shareios.svg" alt="share IOS" />
				</div>
				<div>
					{{ t("addMessages.ios2") }}
					<img class="addIOS" src="@/assets/icons/addios.svg" alt="add IOS" />
				</div>
			</div>
			<button class="closeModalButton unstyledButton" label="OK" @click="closeModal">OK</button>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted, defineExpose } from "vue";
import { useI18n } from "vue-i18n";
import { useCookies } from "vue3-cookies";
import { useRouter, useRoute } from "vue-router";
import { isMobileBrowser, isStandalone } from "@/composables/usePlatform";
import UAParser from "ua-parser-js";

const { t, locale } = useI18n();
const { cookies } = useCookies();
const route = useRoute();

const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const logoPath = "WinnGo/assets/images/NavBar/WinnGo_rounded.png";

const deferedAddToHomescreen = ref();

const appTitle = computed(() => document.title);
const appUrl = computed(() => window.location.href);
const opened = ref(false);

const setCookie = () => {
	let exdate = new Date();
	const expire = 7;
	exdate.setDate(exdate.getDate() + expire);
	cookies.set("addToHomescreenCalled", "true", "1d");
};

const close = () => {
	setCookie();
	opened.value = false;
};

const closeModal = () => {
	const element = document.getElementById("IOSmodal");
	if (element) element.style.display = "none";
};

const addToHomescreen = (once = true) => {
	const parser = new UAParser();
	const parsedUa = parser.getResult();
	const deviceInfos = {
		os: parsedUa.os.name,
		browser: parsedUa.browser.name,
	};

	const iosElementModal = document.getElementById("IOSmodal");

	if (deferedAddToHomescreen.value) {
		deferedAddToHomescreen.value.prompt();
	} else if (deviceInfos.os === "iOS") {
		if (iosElementModal) iosElementModal.style.display = "block";
	} else if (deviceInfos.os === "Android") {
		alert(t("addMessages.android"));
	} else if (deviceInfos.os === "Windows" && (deviceInfos.browser === "Chrome" || deviceInfos.browser === "Edge")) {
		alert(t("addMessages.windows.chrome"));
	} else if (deviceInfos.os === "Windows" && deviceInfos.browser === "Firefox") {
		alert(t("addMessages.windows.firefox"));
	} else if (deviceInfos.os === "Mac OS") {
		const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

		if (isTouchDevice) {
			if (iosElementModal) iosElementModal.style.display = "block";
		} else {
			if (deviceInfos.browser === "Firefox") {
				alert(t("addMessages.macos.firefox"));
			} else if (deviceInfos.browser === "Chrome") {
				alert(t("addMessages.macos.chrome"));
			} else if (deviceInfos.browser === "Safari") {
				alert(t("addMessages.macos.safari"));
			}
		}
	} else {
		alert(t("addMessages.others"));
	}

	if (once) setCookie();
	opened.value = false;
};

onMounted(() => {
	// const parser = new UAParser();
	// const parsedUa = parser.getResult();
	// const parsedUaDevice = parser.getDevice().os;
	// const deviceInfos = {
	// 	os: parsedUa.os.name,
	// 	browser: parsedUa.browser.name,
	// };

	// console.log("Device Info on Mount:", deviceInfos.os);

	window.addEventListener("beforeinstallprompt", (e) => {
		e.preventDefault();
		deferedAddToHomescreen.value = e;
	});

	const getHomescreenCalledCookie = cookies.get("addToHomescreenCalled");
	if (!isStandalone() && isMobileBrowser.value && !getHomescreenCalledCookie) opened.value = true;
});

defineExpose({ addToHomescreen });
</script>

<style scoped lang="sass">
.addToHomeScreenContainer
	padding: 0.75rem 1rem
	border-top: 1px solid var(--border-regular)
	border-bottom: 1px solid var(--border-regular)
	background: var(--background-primary)
	display: flex
	align-items: center
	gap: 15px
	// position: sticky
	top: 0
.appLogo
	height: 35px
	width: 35px
.content
	color: var(--text-regular)
.appName
	font-weight: var(--font-bold)
	line-height: normal
	display: flex
	align-items: center
	gap: 5px
.rating
	color: var(--warning)
	font-size: 0.8em
.description
	font-size: 0.8em
	line-height: normal
.installButton
	padding: 5px 15px
	min-width: unset
	width: auto
	font-size: 1.1em
	margin-left: auto
.closeButton
	color: var(--text-regular)
	height: 30px
	width: 30px
	padding: 5px
	svg
		height: 100%
		width: 100%

#IOSmodal
	display: none
.modalContent
	margin: auto
	background: var(--ios-alert-background)
	backdrop-filter: blur(5px)
	color: var(--text-regular)
	position: fixed
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	border-radius: 15px
	min-width: 250px
	text-align: center
	overflow: hidden
	.instructions
		padding: 15px 20px
		display: flex
		flex-direction: column
		align-items: center
		gap: 10px
.shareIOS,
.addIOS
	width: 15px
	height: 15px
	filter: brightness(0)
html[data-theme="dark"]
	.shareIOS,
	.addIOS
		filter: brightness(0) invert(1)
.closeModalButton
	color: var(--neutral)
	padding-block: 10px 12px
	border-top: 1px solid var(--ios-alert-border)
	width: 100%
	&:hover
		background: var(--ios-alert-border)
</style>
