export enum GameType {
	LIVE_CASINO = "LC",
	SLOTS = "SL",
	SPORTS = "SP",
	FISHING = "FS",
	LOTTERY = "LT",
	COCKFIGHT = "CF",
	FASTGAMES = "FG",
}

//Only member tag = 'Tester' will see these games
export const testerVendorList = ["KISS918", "KAYA918"];
