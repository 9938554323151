import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { computed } from "vue";
import { routeData } from "@/constant";
import { START_LOCATION } from "vue-router";
import { isLogin } from "@/composables/useAuth";
import { isIOS, getIOSNavGesture, refreshIOSNavGesture, isDesktop } from "@/composables/usePlatform";
import store from "@/store/index";

declare global {
	interface Window {
		prevPage: any;
		appBack: any;
		stale: boolean;
	}
}

window.prevPage = { position: 0, historyLength: 1 };
window.stale = false;

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: () => import("@/pages/home/HomePage.vue"),
	},
	{
		path: "/scan",
		name: "scan",
		component: () => import("@/pages/ScanQRPage.vue"),
	},
	{
		path: "/qrcode",
		name: "qrcode",
		component: () => import("@/pages/QRCodePage.vue"),
	},
	{
		path: "/checkin",
		name: "checkin",
		component: () => import("@/pages/CheckInPage.vue"),
	},
	{
		path: "/interest",
		name: "interest",
		component: () => import("@/pages/interest/GoPlusPage.vue"),
	},
	{
		path: "/interest/cashin",
		name: "cashin",
		component: () => import("@/pages/interest/CashInPage.vue"),
	},
	{
		path: "/interest/cashout",
		name: "cashout",
		component: () => import("@/pages/interest/CashOutPage.vue"),
	},
	{
		path: "/promotion",
		name: "promotion",
		component: () => import("@/pages/PromotionPage.vue"),
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import("@/pages/information/ContactPage.vue"),
	},
	{
		path: "/aboutus",
		name: "aboutus",
		component: () => import("@/pages/information/AboutUsPage.vue"),
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("@/pages/information/TermsPage.vue"),
	},
	{
		path: "/privacypolicy",
		name: "privacypolicy",
		component: () => import("@/pages/information/PrivacyPolicyPage.vue"),
	},
	{
		path: "/responsiblegaming",
		name: "responsiblegaming",
		component: () => import("@/pages/information/ResponsibleGamingPage.vue"),
	},
	{
		path: "/vip",
		name: "vip",
		component: () => import("@/pages/VipPage.vue"),
	},
	{
		path: "/slots",
		name: "slots",
		component: () => import("@/pages/games/SlotsPage.vue"),
	},
	{
		path: "/casino",
		name: "casino",
		component: () => import("@/pages/games/CasinoPage.vue"),
	},
	{
		path: "/fishing",
		name: "fishing",
		component: () => import("@/pages/games/FishingPage.vue"),
	},
	{
		path: "/fastgame",
		name: "fastgame",
		component: () => import("@/pages/games/FastGamePage.vue"),
	},
	{
		path: "/sports",
		name: "sports",
		component: () => import("@/pages/games/SportsPage.vue"),
	},
	{
		path: "/settings",
		name: "myaccount",
		component: () => import("@/pages/MyAccountPage.vue"),
	},
	{
		path: "/profile",
		name: "profile",
		component: () => import("@/pages/account/ProfilePage.vue"),
	},
	{
		path: "/bankinfo",
		name: "bankinfo",
		component: () => import("@/pages/account/BankInfoPage.vue"),
	},
	{
		path: "/deposit",
		name: "deposit",
		component: () => import("@/pages/account/DepositPage.vue"),
	},
	{
		path: "/withdrawal",
		name: "withdrawal",
		component: () => import("@/pages/account/WithdrawalPage.vue"),
	},
	{
		path: "/statement",
		name: "statement",
		component: () => import("@/pages/account/StatementPage.vue"),
	},
	{
		path: "/changepassword",
		name: "changepassword",
		component: () => import("@/pages/account/ChangePasswordPage.vue"),
	},
	// {
	// 	path: "/bonus",
	// 	name: "bonus",
	// 	component: () => import("@/pages/account/BonusPage.vue"),
	// },
	{
		path: "/referral",
		name: "referral",
		component: () => import("@/pages/account/ReferralPage.vue"),
	},
	{
		path: "/language",
		name: "language",
		component: () => import("@/pages/LanguagePage.vue"),
	},
	{
		path: "/theme",
		name: "theme",
		component: () => import("@/pages/ThemePage.vue"),
	},
	{
		path: "/autodeposit",
		name: "autodeposit",
		component: () => import("@/pages/AutoDepositPage.vue"),
	},
	{
		path: "/inbox",
		name: "inbox",
		component: () => import("@/pages/InboxPage.vue"),
	},
	{
		path: "/:notFound(.*)",
		name: "notfound",
		component: () => import("@/pages/NotFoundPage.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/pages/authentication/LoginPage.vue"),
	},
	{
		path: "/member/forgotpassword",
		name: "forgotpassword",
		component: () => import("@/pages/authentication/ForgotPasswordPage.vue"),
	},
	{
		path: "/register/:referralCode?",
		name: "register",
		component: () => import("@/pages/authentication/RegisterPage.vue"),
	},
	// {
	// 	path: "/member/resetpassword",
	// 	name: "resetpassword",
	// 	component: () => import("@/pages/authentication/ResetPasswordPage.vue"),
	// },
	// {
	//   path: "/interchange/:token/:locale",
	//   name: "home-token",
	//   component: InterChangeLogin,
	//   props: true,
	// },
	// {
	//   path: "/rank",
	//   name: "rank",
	//   component: () => import("@/pages/RankPage.vue"),
	// },
	// {
	//   path: "/vendor/:category/:vendorId",
	//   name: "vendor",
	//   component: Vendor,
	//   props: true,
	// },
	// {
	//   path: "/launchGame/:category/:vendorId",
	//   name: "LaunchGame",
	//   component: MobileLaunchGame,
	//   props: true,
	// },
	// {
	// 	path: "/quicktransfer",
	// 	name: "quicktransfer",
	// 	component: () => import("@/pages/QuickTransferPage.vue"),
	// },
	// {
	//   path: "/download",
	//   name: "download",
	//   component: () => import("@/pages/DownloadPage.vue"),
	// },
	// {
	// 	path: "/transfer",
	// 	name: "transfer",
	// 	component: () => import("@//pages/account/TransferPage.vue"),
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.name == from.name) return; // if changes only happen in query
		if (isDesktop.value) document.querySelector("#app").scrollTop = 0;
		return { top: 0, behavior: "smooth", name: name };
	},
});

router.beforeEach(async (to, from, next) => {
	// ================= Redirect Start =================

	let redirectPath = null;
	const { redirect, ...params } = from.query;
	if (from.query?.redirect && from.meta.redirect) {
		redirectPath = Array.isArray(from.query.redirect)
			? decodeURIComponent(from.query.redirect[0])
			: decodeURIComponent(from.query.redirect);
		from.query.redirect = null;
	}

	// ================= Redirect End =================

	// ================= Authentication Start =================

	// only fetch when coming from other domain
	if (from === START_LOCATION) {
		store.dispatch("progressTracker/setAppLoading", true);

		await store.dispatch("identityServer/tryLogin");
		if (isLogin.value) await store.dispatch("member/fetchMemberBank");
		to.meta.refresh = { value: true };
	} else to.meta.refresh = { value: false };
	const routeDataItem = routeData.find((item) => item.title == to.name);
	const memberBankExist = computed(() => store.getters["member/getIsMemberBankExist"]);

	to.meta.auth = { done: true };
	to.meta.bank = { done: true };

	const login = routeData.find((item) => item.title == "login");
	const bankInfo = routeData.find((item) => item.title == "bankinfo");

	// prevent accessing member pages without authentication
	if (routeDataItem?.validateLogin && !isLogin.value) next(login.link);
	// prevent accessing guest only pages after login
	else if (routeDataItem?.guestOnly && isLogin.value) next("/");
	// prevent accessing bank related pages without bank info
	else if (routeDataItem?.validateBank && !memberBankExist.value) next(bankInfo.link);
	// pass navigation guard with redirect
	else if (redirectPath) {
		next({ path: redirectPath, query: params });
	}
	// pass navigation guard normally
	else {
		next();
	}

	// ================= Authentication End =================

	// ================= Page Transition Start =================

	// const getDepth = (path) => {
	// 	return path.split("/").filter((seg) => seg.length > 0).length;
	// };

	// const toDepth = getDepth(to.path);
	// const fromDepth = getDepth(from.path);

	const { swipeBack, swipeForward } = getIOSNavGesture();

	/*
		position	length	action
		-			=		back
		=			-		back
		=			+		back
		=			=		new page / back (alternating between new page and back)
		+			-		new page
		+			+		new page
		+			=		forward
	*/
	// Based on the states above, we can determine if the back button is pressed

	// --- For debugging ---
	// console.log(window.prevPage.position, window.prevPage.historyLength);
	// console.log(window.history.state.position, window.history.length);
	// console.log(window.stale);

	const isBack = () => {
		return (
			window.appBack ||
			(window.stale &&
				window.prevPage.position == window.history.state.position &&
				window.prevPage.historyLength == window.history.length) ||
			(window.prevPage.position == window.history.state.position &&
				window.prevPage.historyLength < window.history.length) ||
			(window.prevPage.position > window.history.state.position &&
				window.prevPage.historyLength == window.history.length) ||
			(window.prevPage.position == window.history.state.position &&
				window.prevPage.historyLength > window.history.length)
		);
	};

	if (isIOS.value && (swipeBack || swipeForward)) {
		to.meta.pageTransition = { name: "instant" };
		from.meta.pageTransition = { name: "instant" };
		refreshIOSNavGesture();
	} else {
		if (from.path != "/" && (isBack() || to.path == "/")) {
			to.meta.pageTransition = { name: "page-right" };
			from.meta.pageTransition = { name: "page-right" };
		} else {
			to.meta.pageTransition = { name: "page-left" };
			from.meta.pageTransition = { name: "page-left" };
		}
	}

	if (window.stale) window.stale = false;
	else if (
		window.prevPage.position == window.history.state.position &&
		window.prevPage.historyLength == window.history.length
	)
		window.stale = true;
	window.prevPage = { position: window.history.state.position, historyLength: window.history.length };
	window.appBack = false;

	// ================= Page Transition End =================
});

router.afterEach((to, from) => {
	store.dispatch("progressTracker/setAppLoading", false);
});

export default router;
