import { setThemePreference, currentTheme } from "./useDarkTheme";

const imgUrlDomain = process.env.VUE_APP_Media_Domain;

const html = document.querySelector("html");
const body = document.querySelector("body");

const toggleTheme = () => {
	if (currentTheme.value == "light") setThemePreference("dark");
	else if (currentTheme.value == "dark") setThemePreference("light");
};

let wallpaperId = 0;

const defaultWallpaper = () => {
	if (wallpaperId == 0) {
		body.style.background = `url('${imgUrlDomain}WinnGo/assets/images/Home/wallpaper_3.jpg')`;
		body.style.backgroundSize = "cover";
		body.style.backgroundPosition = "center";
	}
};

const cycleWallpaper = () => {
	if (wallpaperId == 0) {
		body.style.background = `url('${imgUrlDomain}WinnGo/assets/images/Home/wallpaper_3.jpg')`;
		body.style.backgroundSize = "cover";
		body.style.backgroundPosition = "center";
	}
	if (wallpaperId == 1) {
		body.style.background = `url('${imgUrlDomain}WinnGo/assets/images/Home/wallpaper_5.jpg')`;
		body.style.backgroundSize = "cover";
		body.style.backgroundPosition = "center";
	}
	if (wallpaperId == 2) {
		body.style.background = `url('${imgUrlDomain}WinnGo/assets/images/Home/wallpaper_6.jpg')`;
		body.style.backgroundSize = "cover";
		body.style.backgroundPosition = "center";
	}
	if (wallpaperId == 3) {
		body.style.background = `url('${imgUrlDomain}WinnGo/assets/images/Home/wallpaper_7.jpg')`;
		body.style.backgroundSize = "cover";
		body.style.backgroundPosition = "center";
	}
	if (wallpaperId < 3) wallpaperId++;
	else wallpaperId = 0;
};
defaultWallpaper();

const app = document.querySelector("#app");
// right button
app.addEventListener("click", (e) => {
	if (e.clientX > app.getBoundingClientRect().right) toggleTheme();
});

// left button
app.addEventListener("click", (e) => {
	if (e.clientX < app.getBoundingClientRect().left && e.clientX > 0) cycleWallpaper();
});
